import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import Pagination from 'components/board/board_pagination';
import TableContainer from '@mui/material/TableContainer';
import { accountInsightsStoreCtx } from '../../store';
import useStyles from './styles';
import SuccessfulTable from './components/SuccessfulTable';
import UnsuccessfulTable from './components/UnsuccessfulTable';

const AssessmentsTable = observer(() => {
    const [activeTab, setActiveTab] = useState(0);
    const { insightAuditions, handleChangeAssessmentsPage } = useContext(accountInsightsStoreCtx);

    const { wrapper, title, tabWrapper, activeTab: activeTabClass, tab, noData, statusLabel, tableContainer } = useStyles();

    const handleTabChange = (newTab) => {
        setActiveTab(newTab);
    };

    const hasData = insightAuditions && insightAuditions.items && insightAuditions.items.length > 0;

    const getAuditionStatus = (audition) => {
        const { archived, closed } = audition;

        let status = '';
        if (archived) {
            status = 'archived';
        } else if (closed) {
            status = 'closed';
        } else {
            status = 'open';
        }

        return (
            <span className={statusLabel}>({status})</span>
        );
    };

    return (
        <div className={wrapper}>
            <div className={title}>Assessments</div>
            <div className={tabWrapper}>
                <div
                    className={clsx(tab, { [activeTabClass]: activeTab === 0 })}
                    onClick={() => handleTabChange(0)}
                >
                    SUCCESSFUL CANDIDATES
                </div>
                <div
                    className={clsx(tab, { [activeTabClass]: activeTab === 1 })}
                    onClick={() => handleTabChange(1)}
                >
                    UNSUCCESSFUL CANDIDATES
                </div>
            </div>
            <TableContainer className={tableContainer}>
                {
                    activeTab === 0
                        ? <SuccessfulTable data={insightAuditions} hasData={hasData} getAuditionStatus={getAuditionStatus} />
                        : <UnsuccessfulTable data={insightAuditions} hasData={hasData} getAuditionStatus={getAuditionStatus} />
                }
                {
                    hasData && (
                        <div className="u-mrg--tx2">
                            <Pagination
                                currentPage={insightAuditions?.currentPage}
                                totalPages={Math.ceil(insightAuditions?.total / 4)}
                                handleChangePage={handleChangeAssessmentsPage}
                            />
                        </div>
                    )
                }
            </TableContainer>
            {
                !hasData && (
                    <div className={noData}>
                        No Assessments
                    </div>
                )
            }
        </div>
    );
});

export default AssessmentsTable;
