import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';

import { downloadCSV } from 'helper/commonFunctions';
import ExportingPopup from 'libraries/SnackbarProgress';

import DownloadButton from './DownloadButton';

import styles from './styles';

const FeedbackModal = ({ classes, auditionSlug, open, loadDataRequest, loadParams, loadFilterRequest,
    onExportCSV, requestExportCsv, canDownloadCSV, onClose, renderItem, title, maxWidth }) => {
    const [dataList, setDataList] = useState({});
    const [filterData, setFilterData] = useState(null);
    const [dataListLoading, setDataListLoading] = useState(false);
    const [selectedScore, setSelectedScore] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [CSVPercents, setCSVPercents] = useState(0);
    const [cancelImporting, setCancelImporting] = useState(false);
    const [dialog_exporting_error, setDialogExportingError] = useState(false);
    const [dialogExporting, setDialogExporting] = useState(false);

    useEffect(() => {
        if (!open) return;
        loadData();
    }, [open, currentPage, selectedScore]);


    useEffect(() => {
        if (open) {
            loadDataFilter();
        } else {
            setCurrentPage(1);
            setSelectedScore('All');
            setDataList({});
            setFilterData(null);
        }
    }, [open]);

    const handleSelectedScore = (score) => {
        if (score === selectedScore) return;
        setDataList({});
        setCurrentPage(1);
        setSelectedScore(score);
    };

    const loadData = () => {
        setDataListLoading(true);
        loadDataRequest({ ...loadParams, page: currentPage, score: selectedScore === 'All' ? null : selectedScore })
            .then(({ success, data }) => {
                if (success && data) {
                    setDataList({
                        ...data,
                        items: dataList.items ? [...dataList.items, ...data.items] : data.items
                    });
                }
            })
            .finally(() => {
                setDataListLoading(false);
            });
    };

    const loadDataFilter = () => {
        loadFilterRequest(loadParams)
            .then(({ success, data }) => {
                if (success && data) {
                    setFilterData(data);
                }
            });
    };

    const loadMore = () => {
        if (!dataList.hasMorePages) return;
        setDataList({ ...dataList, hasMorePages: false });
        setCurrentPage(currentPage + 1);
    };

    const exportCSV = () => {
        if (onExportCSV) onExportCSV();

        setDialogExporting(true);
        setCancelImporting(false);
        setDialogExportingError(false);
        setCSVPercents(0);

        downloadFeedbackCSV();
    };

    const downloadFeedbackCSV = () => {
        const handleError = () => {
            setDialogExportingError(true);
            setCancelImporting(false);
            setTimeout(() => {
                setDialogExporting(false);
            }, 5000);
        };

        requestExportCsv({ ...loadParams, page: currentPage })
            .then((response) => {
                let csv;
                if (response.csv) {
                    csv = response.csv;
                } else {
                    csv = response.data.csv;
                }
                const { success, totalItemsCount, offset } = response;

                const handleSuccess = (fullString) => {
                    if (!cancelImporting) {
                        setCancelImporting(false);
                        setCSVPercents(100);
                        downloadCSV(fullString, `Candidate-feedback-${auditionSlug}`);
                    }

                    setTimeout(() => {
                        setDialogExporting(false);
                    }, 1000);
                };


                if (success) {
                    if (parseInt(totalItemsCount, 10) > parseInt(offset, 10)) {
                        const maxPage = Math.ceil(totalItemsCount / offset);
                        let page = 2;
                        let fullString = csv;

                        const CSVPercentsCalc = () => parseInt((offset * (page - 1) * 100) / totalItemsCount, 10);
                        const makeRequest = () => {
                            requestExportCsv({ ...loadParams, page })
                                .then((newResponse) => {
                                    if (newResponse.success) {
                                        fullString += `\n${newResponse.csv}`;
                                        page += 1;
                                        if (page <= maxPage) {
                                            setCSVPercents(CSVPercentsCalc());
                                            makeRequest();
                                        } else {
                                            handleSuccess(fullString);
                                        }
                                    } else {
                                        handleError();
                                    }
                                });
                        };
                        setCSVPercents(CSVPercentsCalc());
                        makeRequest();
                    } else {
                        handleSuccess(csv);
                    }
                } else {
                    handleError();
                }
            })
            .catch(() => {
                handleError();
            });
    };

    const cancelExporting = () => {
        setCancelImporting(true);
        setTimeout(() => {
            setDialogExporting(false);
        }, 1000);
    };

    const retryCSV = () => {
        setDialogExportingError(false);
        setCSVPercents(0);
        downloadFeedbackCSV();
    };

    return (
        <>
            <Dialog
                maxWidth={maxWidth || 'sm'}
                open={open}
                onClose={onClose}
                handleClose={onClose}
                titleComponent={title}
            >
                {
                    filterData && (
                        <div className="u-dsp--f u-ai--center u-jc--end">
                            <span className={classes.filterLabel}>Show Rating</span>
                            { filterData.map(({ count, score }) => (
                                <span
                                    role="presentation"
                                    className={clsx(classes.score, selectedScore === score && classes.selectedScore)}
                                    onClick={() => { handleSelectedScore(score); }}
                                >
                                    {score}
                                    <span className={classes.countLabel}>({count})</span>
                                </span>
                            )) }
                        </div>
                    )
                }
                { canDownloadCSV && <DownloadButton onClick={exportCSV} /> }
                <InfiniteScroll
                    pageStart={0}
                    useWindow={false}
                    loadMore={loadMore}
                    threshold={0}
                    hasMore={dataList.hasMorePages && !dataListLoading}
                >
                    {
                        dataList.items && dataList.items.map(renderItem)
                    }
                </InfiniteScroll>
                {
                    dataListLoading && (
                        <div className="u-txt--center u-mrg--tx3">
                            <CircularProgress color="primary" size={20} />
                        </div>
                    )
                }
            </Dialog>
            <ExportingPopup
                open={dialogExporting}
                message="Exporting candidates to CSV…"
                onCancel={cancelExporting}
                error={dialog_exporting_error}
                onRetry={retryCSV}
                canceled={cancelImporting}
                percents={CSVPercents}
            />
        </>
    );
};

export default withStyles(styles)(withRouter(FeedbackModal));
