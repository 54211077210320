import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import AssessmentEvents from 'events/AssessmentEvents';

import { getInsightsCompletions, exportInsightsCompletions } from 'requests/AssessmentRequests';
import QuestionTooltipBlack from 'components/tooltips/QuestionTooltipBlack';
import TimeRange from 'components/insight_components/time_range';

import { DATE_RANGES } from 'helper/constants';
import InsightsSectionWrapper from 'components/insight_components/insight_section_wrapper';
import CompletionsChart from 'components/insight_components/completions_chart';
import DownloadCsv from 'components/insight_components/download_csv';

import CompletionImg from 'img/insight_completion.svg';

const CompletionsData = observer(({ audition }) => {
    const [dateRange, setDateRange] = useState([moment().subtract(7, 'd').format(), moment().format()]);
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [isCustomRange, setIsCustomRange] = useState(true);
    const [isFirstLoading, setIsFirstLoading] = useState(true);

    useEffect(() => {
        getData();
    }, [dateRange]);

    const { name: ttName, uuid: ttId, slug } = audition;

    const getData = () => {
        setLoading(true);
        getInsightsCompletions(slug, dateRange)
            .then(({ success, data }) => {
                if (success) {
                    setChartData(data);
                }
            })
            .finally(() => {
                setIsFirstLoading(false);
                setLoading(false);
            });
    };

    const header = (
        <Typography variant="h5" className="u-txt--left">
            Candidate Completions
            <QuestionTooltipBlack
                className="u-pdn--tx2 u-pdn--bx2"
                label={(<>
                    Completion chart show numbers<br />of completed assessments by<br />candidates over time
                    </>
                )}
            />
        </Typography>
    );

    const handleEventTimeRangeButton = (value) => {
        AssessmentEvents.INSIGHTS_COMPLETIONS_FILTERED({ ttId, ttName, filterType: value });
    };

    const handleEventTimeRangeCustom = (value) => {
        AssessmentEvents.INSIGHTS_COMPLETIONS_FILTERED({
            ttId,
            ttName,
            filterType: 'selected date',
            customDate: {
                from: value[0],
                to: value[1]
            }
        });
    };

    return (
        <InsightsSectionWrapper
            header={header}
            rightPart={!isFirstLoading ? (
                <TimeRange
                    handleDateRange={setDateRange}
                    onOptionClick={handleEventTimeRangeButton}
                    onCustomRange={handleEventTimeRangeCustom}
                    setIsCustomRange={setIsCustomRange}
                    availableOptions={[DATE_RANGES.ALL_TIME, DATE_RANGES.LAST_7_DAYS, DATE_RANGES.LAST_30_DAYS]}
                />
            ) : null}
            imgSrc={CompletionImg}
            loading={loading}
            isEmpty={!chartData && !isCustomRange}
            audition={audition}
            emptyLabel="completion"
        >
            <CompletionsChart
                value={chartData}
                tooltipLabel="Completion"
            />
            <DownloadCsv
                request={() => exportInsightsCompletions(slug, dateRange)}
                enabled={Boolean(chartData)}
                filename={`${audition.name || 'All'}-completions-${new Date().toISOString().slice(0, 10)}.csv`}
                onExport={() => {
                    AssessmentEvents.INSIGHTS_COMPLETIONS_DOWNLOADED({ ttId, ttName });
                }}
            />
        </InsightsSectionWrapper>
    );
});

export default CompletionsData;
