import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { accountInsightsStoreCtx } from '../../store';
import SkillsBreakdown from './components/SkillsBreakdown';
import HireData from './components/HireData';
import CandidateCsat from './components/CandidateCsat';
import useStyles from './styles';

const DataMetrics = observer(() => {
    const [activeTab, setActiveTab] = useState(0);
    const { skillsBreakdown, hireData, candidateCsat, baseParams } = useContext(accountInsightsStoreCtx);

    const { tabWrapper, activeTab: activeTabClass, tab } = useStyles();

    const handleTabChange = (newTab) => {
        setActiveTab(newTab);
    };

    return (
        <div className="u-mrg--tx5">
            <div className={tabWrapper}>
                <div
                    className={clsx(tab, { [activeTabClass]: activeTab === 0 })}
                    onClick={() => handleTabChange(0)}
                >
                    SKILLS BREAKDOWN
                </div>
                <div
                    className={clsx(tab, { [activeTabClass]: activeTab === 1 })}
                    onClick={() => handleTabChange(1)}
                >
                    HIRE DATA
                </div>
                <div
                    className={clsx(tab, { [activeTabClass]: activeTab === 2 })}
                    onClick={() => handleTabChange(2)}
                >
                    CANDIDATE CSAT
                </div>
            </div>
            {activeTab === 0 && <SkillsBreakdown data={skillsBreakdown} />}
            {activeTab === 1 && <HireData data={hireData} generalFilterParams={baseParams} />}
            {activeTab === 2 && <CandidateCsat data={candidateCsat} generalFilterParams={baseParams} />}
        </div>
    );
});

export default DataMetrics;
