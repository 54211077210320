import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { DATE_RANGES } from 'helper/constants';
import DateRangePicker from './DateRangePicker';
import styles from './styles';

const OPTIONS_MAP = {
    [DATE_RANGES.ALL_TIME]: () => [null, null],
    [DATE_RANGES.LAST_7_DAYS]: () => [moment().subtract(7, 'd').format(), moment().format()],
    [DATE_RANGES.LAST_30_DAYS]: () => [moment().subtract(30, 'd').format(), moment().format()],
    [DATE_RANGES.LAST_YEAR]: () => [moment().subtract(1, 'y').format(), moment().format()]
};

const ALL_OPTIONS = [DATE_RANGES.ALL_TIME, DATE_RANGES.LAST_7_DAYS, DATE_RANGES.LAST_30_DAYS, DATE_RANGES.LAST_YEAR];

const TimeRange = observer(({
    classes, setIsCustomRange = () => {}, handleDateRange = () => {}, availableOptions = ALL_OPTIONS,
    onOptionClick = () => {}, onCustomRange = () => {}, defaultRange = DATE_RANGES.LAST_7_DAYS
}) => {
    const [dateRange, setDateRange] = useState(OPTIONS_MAP[defaultRange]());
    const [selectedDateSection, setSelectedDateSection] = useState(1);

    useEffect(() => {
        handleDateRange(dateRange, availableOptions[selectedDateSection]);
    }, [dateRange]);

    const onButtonClick = (option) => {
        const range = OPTIONS_MAP[option]();
        setIsCustomRange(option !== DATE_RANGES.ALL_TIME);
        onOptionClick(option.toLowerCase());
        setSelectedDateSection(availableOptions.indexOf(option));
        setDateRange(range);
    };

    const handleRange = (value) => {
        const newValue = [...value];
        if (!moment(value[1]).isValid()) {
            newValue[1] = new Date();
        }
        const toDate = new Date(newValue[1]);
        const toDateFormatted = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59);

        newValue[1] = moment(toDateFormatted).format();
        onCustomRange(newValue);
        setSelectedDateSection(null);
        setIsCustomRange(true);
        setDateRange(newValue);
    };

    return (
        <>
            {
                availableOptions.map((option, index) => (
                    <Button
                        key={option}
                        className={clsx(classes.button, selectedDateSection === index && classes.buttonActive)}
                        onClick={() => onButtonClick(option)}
                    >
                        {option}
                    </Button>
                ))
            }
            <DateRangePicker dateRange={dateRange} setDateRange={handleRange} />
        </>
    );
});

export default withStyles(styles)(TimeRange);
