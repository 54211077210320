import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import {
    getCandidateRatingFilter, getCandidateRatingList,
    requestExportFeedbackCsv
} from 'requests/AssessmentRequests';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';
import InsightEvents from 'events/InsightEvents';

import FeedbackModal from 'components/insight_components/feedback_modal';
import CandidatePhoto from 'components/photos/candidate_photo';
import Moment from 'react-moment';
import RatingItem from './RatingItem';


import styles from '../styles';
import { statsCtx } from '../statsStore';

const RatingData = observer(({ classes, uuid }) => {
    const [dialogOpened, setDialogOpened] = useState(false);
    const { ratingData, slug } = useContext(statsCtx);
    const { company } = useContext(appCtx);

    const canDownloadCSV = checkHasCompanyFeature(company, 'EXPORT_CANDIDATE_FEEDBACK_DATA');

    const handleDialog = () => {
        setDialogOpened(!dialogOpened);
    };

    const onExportCSV = () => {
        InsightEvents.FEEDBACKS_EXPORTED({
            ttUuid: uuid,
            ttName: slug
        });
    };

    if (!ratingData) return null;

    return (
        <>
            <div className={classes.ratingWrapper}>
                <p className={classes.label}>Candidate rating</p>
                <div className={classes.content}>
                    <RatingItem
                        value={ratingData.rating}
                        valueLabel="/ 5"
                        totalCount={ratingData.count}
                        label="CSAT rating"
                        onClick={handleDialog}
                    />
                </div>
            </div>
            <FeedbackModal
                open={dialogOpened}
                onClose={handleDialog}
                onExportCSV={onExportCSV}
                canDownloadCSV={canDownloadCSV}
                auditionSlug={slug}
                loadParams={{ slug }}
                loadDataRequest={getCandidateRatingList}
                loadFilterRequest={getCandidateRatingFilter}
                requestExportCsv={requestExportFeedbackCsv}
                title="Candidate Feedback"
                renderItem={({ candidate, score, comment, createdAt }) => (
                    <div className={classes.ratingCandidateItem}>
                        <CandidatePhoto
                            user={{ ...candidate, candidateImageUrl: candidate.candidateProfileImage }}
                        />
                        <div className="u-wdt--100p">
                            <div className="u-dsp--f u-jc--sb u-ai--start">
                                <div className={classes.itemName}>
                                    {candidate.fullName}<br />
                                    Rating: {score}/5
                                </div>
                                <Moment
                                    className={classes.itemDate}
                                    format="D MMM YYYY"
                                >
                                    {createdAt}
                                </Moment>
                            </div>
                            {Boolean(comment) && <div className="u-mrg--tx1">{comment}</div>}
                        </div>
                    </div>
                )}
            />
        </>
    );
});

export default withStyles(styles)(withRouter(RatingData));
