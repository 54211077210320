import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { CSVLink } from 'react-csv';
import { withStyles } from '@mui/styles';
import DownloadImg from 'img/download.svg';

const styles = () => ({
    exportButton: {
        minWidth: 145,
        fontWeight: 700
    }
});


const DownloadCsv = observer(({ classes, request, enabled, onExport, filename }) => {
    const [transactionData, setTransactionData] = useState([]);
    const [loading, setLoading] = useState(false);

    const csvLink = useRef();

    const exportCompletions = () => {
        setLoading(true);
        request()
            .then((data) => {
                let resultData;
                if (typeof data === 'string') {
                    resultData = data;
                } else {
                    resultData = data.data;
                }
                if (onExport) {
                    onExport(resultData);
                }
                setTransactionData(resultData);
                csvLink.current.link.click();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="u-txt--right">
            {
                enabled && (
                    <Button
                        disabled={loading}
                        color="primary"
                        className={classes.exportButton}
                        onClick={exportCompletions}
                    >
                        {
                            !loading ? (
                                <>
                                    <img className="u-mrg--rx1" src={DownloadImg} alt="|" />
                                    Download CSV
                                </>
                            ) : (
                                <CircularProgress size={20} color="primary" />
                            )
                        }
                    </Button>
                )
            }
            <CSVLink
                data={transactionData}
                filename={filename}
                className="hidden"
                ref={csvLink}
                target="_blank"
            />
        </div>
    );
});

export default withStyles(styles)(DownloadCsv);
