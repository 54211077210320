import { get } from '../helper/API';

export function getAssessmentsList() {
    return get('employer/company/auditions');
}

export function getCompanyInsights(params) {
    return get('employer/company/insight', params);
}

export function getSkillsBreakdown(params) {
    return get('employer/company/insight/skills', params);
}

export function getHireData(params) {
    return get('employer/company/insight/hire', params);
}

export function getHireFeedbacks(params) {
    return get('employer/company/insight/hire/feedbacks', params);
}

export function getFiltersForHireFeedbacks(params) {
    return get('employer/company/insight/hire/filters-for-feedback', params);
}

export function exportHireFeedbackCsv(params) {
    return get('employer/company/insight/hire/feedbacks/export', params);
}


export function getInsightAuditions(params) {
    return get('employer/company/insight/auditions', params);
}

export function getFeedbackList(params) {
    return get('employer/company/insight/hire/feedbacks', params);
}

export function getCandidateCsat(params) {
    return get('employer/company/insight/csat', params);
}

export function getCsatFeedbacks(params) {
    return get('employer/company/insight/csat/feedbacks', params);
}

export function getFiltersForCsatFeedbacks(params) {
    return get('employer/company/insight/csat/filters-for-feedback', params);
}

export function exportCsatFeedbackCsv(params) {
    return get('employer/company/insight/csat/feedbacks/export', params);
}

export function getCandidateCompletions(params) {
    return get('employer/company/insight/candidate-completions', params);
}

export function exportCompletionsCsv(params) {
    return get('employer/company/insight/candidate-completions/export', params);
}

export function getScoreDistribution(params) {
    return get('employer/company/insight/score-distribution', params);
}

export function exportInsightsScoreDistribution(params) {
    return get('employer/company/insight/score-distribution/export', params);
}
