import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import ButtonBase from '@mui/material/ButtonBase';
import Zendesk from 'helper/zendeskFunctions';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { DATE_RANGES } from 'helper/constants';
import { accountInsightsStoreCtx } from '../../store';
import styles from './styles';
import GraphUpIcon from './img/graphUp.svg';
import GraphDownIcon from './img/graphDown.svg';

const TrendIndicator = ({ trend, classes }) => {
    if (!trend || trend === 0) return null;

    const isPositive = trend > 0;
    return (
        <>
            <img src={isPositive ? GraphUpIcon : GraphDownIcon} alt={isPositive ? 'Graph Up' : 'Graph Down'} />
            <span className={isPositive ? classes.green : classes.red}>{trend}%</span>
            {isPositive ? 'Up' : 'Down'} from last month
        </>
    );
};

const StatisticBlock = ({ title, value, trend, showTrend, classes, children }) => (
    <div className={classes.block}>
        <span className={classes.title}>{title}</span>
        <div className={classes.valueBlock}>
            <span className={classes.value}>{value}</span>
            <div className={classes.valueBottom}>
                {showTrend && trend !== null && trend !== 0 && (
                    <TrendIndicator trend={trend} classes={classes} />
                )}
                {children}
            </div>
        </div>
    </div>
);

const TopStatisticBar = observer(({ classes, company }) => {
    const { dateFilterType, companyInsights } = useContext(accountInsightsStoreCtx);

    const canCompanyCreateCustomRequests = checkHasCompanyFeature(company, 'REQUEST_CUSTOM_ASSESSMENT');
    const showTrend = dateFilterType === DATE_RANGES.LAST_30_DAYS;

    if (!companyInsights) return null;

    const {
        activeUsersCount, requestCustomAssessmentsCount, candidateCSATSTrend, candidateCSATScore, candidateProcessedCount,
        candidateProcessedTrend, rolesAssessedCount, rolesAssessedTrend, timeSavedHours, timeSavedTrend
    } = companyInsights;

    return (
        <div className={clsx('u-dsp--f', classes.wrapper)}>
            <StatisticBlock title="ACTIVE USERS" value={activeUsersCount} classes={classes} />
            <StatisticBlock
                title="CUSTOM ASSESSMENT CREDITS"
                value={requestCustomAssessmentsCount}
                classes={classes}
            >
                {!canCompanyCreateCustomRequests && (
                    <ButtonBase
                        className={classes.baseButton}
                        onClick={() => Zendesk.open(true)}
                    >
                        Talk to Sales
                    </ButtonBase>
                )}
            </StatisticBlock>
            <StatisticBlock
                title="ROLES ASSESSED"
                value={rolesAssessedCount}
                trend={rolesAssessedTrend}
                showTrend={showTrend}
                classes={classes}
            />
            <StatisticBlock
                title="CANDIDATES PROCESSED"
                value={candidateProcessedCount}
                trend={candidateProcessedTrend}
                showTrend={showTrend}
                classes={classes}
            />
            <StatisticBlock
                title="TIME SAVED"
                value={`${timeSavedHours} hours`}
                trend={timeSavedTrend}
                showTrend={showTrend}
                classes={classes}
            />
            <StatisticBlock
                title="CANDIDATE CSAT SCORE"
                value={candidateCSATScore}
                trend={candidateCSATSTrend}
                showTrend={showTrend}
                classes={classes}
            />
        </div>
    );
});

export default withStyles(styles)(TopStatisticBar);
