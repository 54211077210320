import { createContext } from 'react';
import { action, observable, computed } from 'mobx';
import { getCompanyInsights, getSkillsBreakdown, getInsightAuditions, getHireData, getCandidateCsat, getCandidateCompletions,
    getScoreDistribution } from 'requests/AccountInsightsRequests';
import { DATE_RANGES } from 'helper/constants';

class AccountInsightsStore {
    @observable dateFilterType = DATE_RANGES.LAST_30_DAYS;

    @observable timeRange = null;

    @observable selectedAssessment = 'all';

    @observable companyInsights = null;

    @observable skillsBreakdown = null;

    @observable hireData = null;

    @observable candidateCsat = null;

    @observable insightAuditions = null;

    @observable completionsChartData = null;

    @observable scoreDistributionData = null;

    @observable assessmentsTablePage = 1;

    @observable loading = false;

    @computed get baseParams() {
        if (!this.timeRange) {
            return {};
        }

        const result = {
            from: this.timeRange[0],
            to: this.timeRange[1]
        };

        if (this.selectedAssessment !== 'all') {
            result.audition = this.selectedAssessment;
        }
        return result;
    }

    @action setTimeRange = (newTimeRange, dateRange) => {
        this.timeRange = newTimeRange;
        this.dateFilterType = dateRange;
    }

    @action setSelectedAssessment = (newSelectedAssessment) => {
        this.selectedAssessment = newSelectedAssessment;
    }

    @action loadData = async () => {
        this.loading = true;
        const params = this.baseParams;
        try {
            const [
                companyInsightsResult,
                skillsBreakdownResult,
                hireDataResult,
                candidateCsatResult,
                insightAuditionsResult,
                completionsDataResult,
                scoreDistributionResult
            ] = await Promise.allSettled([
                getCompanyInsights(params),
                getSkillsBreakdown(params),
                getHireData(params),
                getCandidateCsat(params),
                getInsightAuditions(params),
                getCandidateCompletions(params),
                getScoreDistribution(params)
            ]);

            if (companyInsightsResult.status === 'fulfilled' && companyInsightsResult.value.success) {
                this.companyInsights = companyInsightsResult.value.data;
            } else {
                this.companyInsights = null;
            }

            if (skillsBreakdownResult.status === 'fulfilled' && skillsBreakdownResult.value.success) {
                this.skillsBreakdown = skillsBreakdownResult.value.data;
            } else {
                this.skillsBreakdown = null;
            }

            if (hireDataResult.status === 'fulfilled' && hireDataResult.value.success) {
                this.hireData = hireDataResult.value.data;
            } else {
                this.hireData = null;
            }

            if (candidateCsatResult.status === 'fulfilled' && candidateCsatResult.value.success) {
                this.candidateCsat = candidateCsatResult.value.data;
            } else {
                this.candidateCsat = null;
            }

            if (insightAuditionsResult.status === 'fulfilled' && insightAuditionsResult.value.success) {
                this.insightAuditions = insightAuditionsResult.value.data;
            } else {
                this.insightAuditions = null;
            }

            if (completionsDataResult.status === 'fulfilled' && completionsDataResult.value.success) {
                this.completionsChartData = completionsDataResult.value.data;
            } else {
                this.completionsChartData = null;
            }

            if (scoreDistributionResult.status === 'fulfilled' && companyInsightsResult.value.success) {
                this.scoreDistributionData = scoreDistributionResult.value.data;
            } else {
                this.scoreDistributionData = null;
            }
        } finally {
            this.loading = false;
        }
    }

    @action loadInsightAssessments = async () => {
        const params = this.baseParams;
        const result = await getInsightAuditions({ ...params, page: this.assessmentsTablePage });
        if (result.success) {
            this.insightAuditions = result.data;
        }
    }

    @action handleChangeAssessmentsPage = (page) => {
        this.assessmentsTablePage = page;
        this.loadInsightAssessments();
    };
}

export const accountInsightsStore = new AccountInsightsStore();
export const accountInsightsStoreCtx = createContext(accountInsightsStore);
