import React from 'react';
import { withStyles } from '@mui/styles';
import RetinaImage from 'react-retina-image';
import { checkPluralNew } from 'helper/commonFunctions';

const styles = theme => ({
    text: {
        fontWeight: 600,
        fontSize: 16,
        color: theme.palette.grey[900],
        whiteSpace: 'pre-wrap',
        marginTop: 20
    },
    top: {
        width: 'calc(100% + 48px)',
        height: 43,
        margin: '-48px -24px 25px'
    },
    companyLogo: {
        color: theme.palette.grey[900],
        fontSize: 25,
        fontFamily: 'Proximanova-Extrabold'
    }
});

const CardCompanyLogo = ({ company, userAudition, hideCandidateDetails, gradeableCard, countCandidates, classes, opportunitiesList }) => {
    const auditionsNames = [];
    const brand = {};

    if (userAudition?.brandColor) {
        brand.color = userAudition.brandColor;
        brand.logoUrl = userAudition.brandLogo?.url || [];
    } else if (company) {
        brand.color = company.company_color || company.companyColor;
        brand.logoUrl = company.company_image_url || company.companyImageUrl;
    }

    if (opportunitiesList) {
        opportunitiesList.forEach(({ columns }) => {
            columns.forEach(({ audition }) => {
                if (!audition) return;
                const { name } = audition;
                auditionsNames.push(name);
            });
        });
    }

    return (
        <header role="banner">
            {brand.color && <div className={classes.top} style={{ backgroundColor: brand.color }} />}
            {
                brand.logoUrl?.length ? (
                    <div
                        className={classes.companyLogo}
                        role="presentation"
                        style={{ height: 45, maxWidth: 200 }}
                    >
                        <RetinaImage
                            alt="Logo"
                            src={brand.logoUrl?.[0]}
                            style={{ height: 45 }}
                        />
                    </div>
                ) : <div className={classes.companyLogo}>{company.name}</div>
            }
            {
                Boolean(auditionsNames && auditionsNames.length) && (
                    <p className={classes.text}>
                        A candidate report card for {checkPluralNew(auditionsNames.length, 'assessment')} <b>{auditionsNames.join(', ')}</b> has been shared with you.
                        You can view {gradeableCard ? 'and grade ' : ''}{checkPluralNew(countCandidates, "candidate's", 'candidates')} answers{hideCandidateDetails ? ' but candidate identifiers such as name and email have been hidden' : ''}.
                    </p>
                )
            }
        </header>
    );
};

export default withStyles(styles)(CardCompanyLogo);
