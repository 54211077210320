import * as React from 'react';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        width: 270
    }
}));

export default function BasicDateRangePicker({ dateRange, setDateRange }) {
    const { root } = useStyles();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
                startText="Select Date"
                calendars={1}
                value={dateRange}
                onChange={(newValue) => {
                    const formattedDates = newValue.map(item => moment(item).format());
                    setDateRange(formattedDates);
                }}
                renderInput={(startProps, endProps) => {
                    const {
                        inputProps: { value: startValue }
                    } = startProps;
                    const {
                        inputProps: { value: endValue }
                    } = endProps;
                    const { inputProps } = startProps;
                    const { onChange, ...otherInputProps } = inputProps;
                    const formatStr = 'MMM Do, YYYY';
                    const formattedStartValue = startValue ? moment(startValue).format(formatStr) : '';
                    const formattedEndValue = endValue ? moment(endValue).format(formatStr) : '';
                    return (
                        <TextField
                            {...startProps}
                            helperText=""
                            className={root}
                            inputProps={{
                                ...otherInputProps,
                                value: `${formattedStartValue} - ${formattedEndValue}`
                            }}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );
}
